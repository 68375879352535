<template>
  <div class="ma-3">
    <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        class="form ma-md-10"
    >
      <v-row>
        <v-col
            :md="admin ? '3' : '6'"
            cols="12"
        >
          <v-autocomplete
              v-model="insurance"
              :items="insurances"
              :label="inputLables.insurance"
              :placeholder="inputLables['insuranceSearch']"
              :rules="insuranceRules"
              color="primary"
              hide-no-data
              hide-selected
              item-text="description"
              item-value="path"
              required
              return-object
          ></v-autocomplete>
        </v-col>
        <v-col
            :md="admin ? '3' : '6'"
            cols="12"
        >
          <v-menu
              v-model="consultationDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="dateRangeText"
                  :label="inputLables['dateFromTo']"
                  :rules="timeRules"
                  clearable
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                first-day-of-week="1"
                v-model="createDate"
                :allowed-dates="allowedDays"
                color="primary"
                range
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
            v-if="admin"
            cols="12"
            md="3"
        >
          <v-autocomplete
              v-model="bill"
              :items="bills"
              :label="inputLables['bill']"
              :placeholder="inputLables['billSearch']"
              :search-input.sync="searchBill"
              clearable
              color="primary"
              hide-no-data
              hide-selected
              item-text="description"
              item-value="id"
              return-object
          ></v-autocomplete>
        </v-col>
        <v-col
            v-if="admin"
            cols="12"
            md="3"
        >
          <v-autocomplete
              v-model="creator"
              :items="creators"
              :label="inputLables['creator']"
              :placeholder="inputLables['creatorSearch']"
              :search-input.sync="searchCreator"
              clearable
              color="primary"
              hide-no-data
              hide-selected
              item-text="username"
              item-value="username"
              return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <div v-if="admin">
        <v-row v-if="insurance.path === 'advocardPrivate' || insurance.path === 'advocardInternational'">
          <v-spacer></v-spacer>
          <v-col>
            <v-checkbox v-model="exportAssigned" :label="inputLables['exportAssigned']"></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col>
          <v-select v-if="insurance.path !== 'MPU'" v-model="filter" :items="filters" :label="inputLables['filter']" clearable item-text="type"
                    multiple return-object @change="filterChanged()">
            <template v-slot:selection="{ item }">
              <v-chip color="primary" large>
                                   <span>{{ item.type }}
                                     <!-- Schriftlich und Mündlich für OnlineBeratung -->
                                     <span v-if="item.type === 'OnlineBeratung'">
                                          <v-chip color="accent">Schriftl. {{ item.counter }}</v-chip>
                                          <v-chip color="accent">Mündl. {{ item.counter2 }}</v-chip>
                                     </span>
                                     <span v-else>
                                          <v-chip color="accent">{{ item.counter }}</v-chip>
                                     </span>
                                         <span v-if="item.unassigned > 0">
                                          <v-chip class="black--text" color="warning">davon unzugewiesen {{
                                              item.unassigned
                                            }}  </v-chip>
                                     </span>
                                        <span v-if="item.open > 0">
                                          <v-chip color="accent">davon offen {{ item.open }}</v-chip>
                                     </span>
                                   </span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row
          class="mb-10"
          justify="end"
          md="4"
      >
        <v-btn
            class="mr-4"
            color="error"
            @click="$router.back()"
        >
          {{ this.$t('button.cancel') }}
        </v-btn>

        <v-btn
            :disabled="!valid"
            class="mr-4"
            color="success"
            @click="getData(false)"
        >
          {{ this.$t('button.export') }}
        </v-btn>
      </v-row>
    </v-form>
    <!-- start Table -->
    <v-card>
      <v-card-title>
        Export
      </v-card-title>
      <v-card-text>
        <v-row justify="end" style="width: 100%">
          <v-col cols="12" md="5">
            <v-text-field
                v-model="searchTable"
                :label="this.$t('export.admin.search')"
                append-icon="mdi-magnify"
                hide-details
                single-line
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn v-if="admin" color="primary" @click="searchForSurname()">
              {{
                (isValid(searchTable) ? (contractClaimSwitch ? "Schadennummern" : "Versicherungsnr.") : "Nachnamen") + " durchsuchen"
              }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-switch v-if="admin && isValid(searchTable)"
                      v-model="contractClaimSwitch" :label="contractClaimSwitch ? 'zu Versicherungsnr. wechseln' : 'zu Schadennummern wechseln'"
                      color="primary"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
            :footer-props="{
                            itemsPerPageOptions: [10, 50, 100, -1],
                            itemsPerPageText: this.$t('export.admin.itemsPerPage'),
                            itemsPerPageAllText: this.$t('export.admin.all'),
                        }"
            :headers="headers"
            :items="exportData"
            :loading="isLoadingTable"
            :no-data-text="this.$t('export.admin.noData')"
            :no-results-text="this.$t('export.admin.noDataFound')"
            :search="searchTable"
        >
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col cols="6">
                <export-delete-item v-bind:url="url" v-bind:item="item" v-on:updateTable="getData(false)"/>
              </v-col>
              <v-col v-if="item.bill" cols="6">
                <v-icon
                    :disabled="moderator"
                    color="primary"
                    small
                    @click="downloadInvoiceById(item.bill.id)"
                >
                  mdi-download
                </v-icon>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.reassign="{ item }">
            <v-layout align-center justify-center>
              <v-icon
                  color="primary"
                  small
                  @click="getAllCreatorsWithFilter([insurance.path + 'DataType'], insurance.path,  item[insurance.path + 'DataType'], item)"
              >
                mdi-account-arrow-right
              </v-icon>
            </v-layout>
          </template>
          <template v-slot:item.id="{ item }">
            <v-layout align-center justify-center>
              <v-icon
                  :disabled="item.bill"
                  color="primary"
                  small
                  @click="openCreateInvoiceDialog(item)"
              >
                mdi-receipt-text-plus
              </v-icon>
            </v-layout>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="reassignDialog">
      <v-card>
        <v-card-title class="text-h5 primary">
          Fall neu zuweisen
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  v-model="sortedCreator"
                  :items="sortedCreators"
                  :placeholder="'Anwalte mit ' + selectedItem[insurance.path + 'DataType'] + 'Berechtigung' "
                  clearable
                  color="primary"
                  hide-no-data
                  hide-selected
                  item-text="username"
                  item-value="username"
                  label="Anwalt *"
                  return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="reassignDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              :disabled="!sortedCreator"
              color="primary"
              text
              @click="reassignItem(selectedItem)"
          >
            Neu zuweisen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createInvoiceDialog" max-width="550">
      <v-card>
        <v-card-title class="text-h5 primary">
          Rechnung generieren
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="invoice.price"
                  color="primary"
                  label="Preis Netto *"
                  placeholder="12,34"
              ></v-text-field>
              <v-text-field
                  v-model="invoice.text"
                  color="primary"
                  label="Beschreibung *"
                  placeholder=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="createInvoiceDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              :disabled="!invoice.price || !invoice.text"
              color="primary"
              text
              @click="createInvoice()"
          >
            Rechnung generieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
        :disabled="exportData.length === 0"
        bottom
        class="mb-6"
        color="success"
        dark
        fab
        fixed
        right
        @click="exportCSV()"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
import i18n from "../plugins/i18n";
import {
  getAdvocardBusinessURL, getAdvocardInternationalURL,
  getAdvocardPrivateURL,
  getAragURL,
  getBillURL, getFilesOfBillsURL, getLawyersURL, getMpuURL,
  getRolandURL,
  requestMode
} from "@/configBuilder";
import {sessionHandler} from "@/request/sessionHandler";
import {showAlert} from "@/utils/alertHandler";
import {downloadCSV} from "@/utils/csvHandler";
import dateFormatter from "../utils/dateFormatter";
import reactiveStorage from "@/plugins/reactiveStorage";
import ExportDeleteItem from "@/components/dialogs/ExportDeleteItem";

export default {
  name: "Export",
  components: {ExportDeleteItem},
  data: () => ({
    sortedCreator: undefined,
    invoice: {price: 0, text: ""},
    createInvoiceDialog: false,
    sortedCreators: [],
    sortedSearchCreator: null,
    contractClaimSwitch: false,
    lazy: false,
    valid: true,
    dialog: false,
    reassignDialog: false,
    selectedItem: {},
    admin: reactiveStorage.user.roles.Admin,
    moderator: reactiveStorage.user.roles.Moderator,
    insurance: {description: "ARAG", path: "arag", fileName: "ARAG"},
    bill: undefined,
    bills: [],
    searchBill: null,
    exportAssigned: false,
    isLoadingBill: false,
    creator: undefined,
    creators: [],
    searchCreator: null,
    isLoadingCreator: false,
    searchTable: '',
    exportData: [],
    unfilteredData: [],
    notAssignedWarning: false,
    notClosedWarning: false,
    headers: [],
    isLoadingTable: false,
    insurances: [
      {description: "ARAG", path: "arag", fileName: "ARAG"},
      {description: "Advocard Business", path: "advocardBusiness", fileName: "AdvocardBusiness"},
      {description: "Advocard International", path: "advocardInternational", fileName: "AdvocardInternational"},
      {description: "Advocard Private", path: "advocardPrivate", fileName: "AdvocardPrivate"},
      {description: "Roland", path: "roland", fileName: "Roland"},
      {description: "MPU", path: "MPU", fileName: "MPU"},
    ],
    createDate: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    consultationDateMenu: false,
    url: "",
    filter: [],
    filters: [],
    // add serviceType to serviceTypeText
    la :  [
      {id: "01", name: "Straf/Owi Straßenverkehr"},
      {id: "02", name: "KfzSchadenersatzRS"},
      {id: "03", name: "KfzVertragSachRS"},
      {id: "04", name: "KfzVersVertragRS"},
      {id: "05", name: "KfzSteuerRS"},
      {id: "21", name: "VerwaltVerkehrsRS"},
      {id: "51", name: "Straf/Owi"},
      {id: "52", name: "SchadenersatzRS"},
      {id: "53", name: "Arbeits-RS"},
      {id: "54", name: "SozialRS"},
      {id: "55", name: "BeratungsRS"},
      {id: "56", name: "VertragsSachRS"},
      {id: "57", name: "Handelsvertreter RS"},
      {id: "58", name: "VersichsvertrRS"},
      {id: "59", name: "SteuerRS"},
      {id: "70", name: "VerwaltungsRS"},
      {id: "71", name: "FirmenvertragsRS"},
      {id: "98", name: "GrundstücksteuerRS"},
      {id: "99", name: "WEG"},
    ]
  }),
  methods: {
    openCreateInvoiceDialog(selectedCase) {
      this.createInvoiceDialog = true;
      this.invoice.case = selectedCase;
    },
    async createInvoice() {
      await sessionHandler();

      await this.$http
          .patch(
              getMpuURL() + "/" + this.invoice.case.id + "/GenerateBill",
              {
                "netPrice": this.invoice.price,
                "text": this.invoice.text
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
                responseType: 'arraybuffer'
              }
          )
          .then(() => {
            this.createInvoiceDialog = false;
            this.getData();
          }).catch(() => {
          });

    },
    filterChanged() {
      this.exportData = this.unfilteredData.filter((item) => {
        return this.filter.filter((filter) => filter.type === item[this.insurance.path + "DataType"]).length > 0
      })
    },
    isValid(input) {
      // Regular expression to match if it is a number.
      let numberRegex = /^\d+$/;

      // Regular expression to match policyNumber format: 1.123.123.1
      let policyNumberRegex = /^\d{1}\.\d{3}\.\d{3}\.\d{1}$/;

      // Regular expression to match serviceNumberError format: 12-123456-1
      let serviceNumberRegex = /^\d{2}-\d{6}-\d{1}$/;

      // Check if the input matches any of the regular expressions.
      return numberRegex.test(input) || policyNumberRegex.test(input) || serviceNumberRegex.test(input);
    },
    isAlreadyFormatted(input) {
      // Regular expression to match policyNumber format: 1.123.123.1
      let policyNumberRegex = /^\d{1}\.\d{3}\.\d{3}\.\d{1}$/;

      // Regular expression to match serviceNumberError format: 12-123456-1
      let serviceNumberRegex = /^\d{2}-\d{6}-\d{1}$/;

      // Check if the input matches any of the regular expressions.
      return policyNumberRegex.test(input) || serviceNumberRegex.test(input);
    },
    async downloadInvoiceById(billId) {
      await sessionHandler();
      await this.$http
          .get(
              getFilesOfBillsURL()
              , {
                mode: requestMode(),
                params: {"billIds": billId},
                headers: {
                  'Accept': 'application/octet-stream',
                  'Content-Type': 'application/octet-stream',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
                responseType: 'arraybuffer'
              }
          )
          .then((d) => {
            if (d.status === 200) {
              let filename = "Bill.zip";
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              const blob = new Blob([d.data], {type: 'application/octet-stream'});
              if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE doesn't allow using a blob object directly as link href.
                // Workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
                return;
              }
              // Other browsers
              // Create a link pointing to the ObjectURL containing the blob
              const blobURL = window.URL.createObjectURL(blob);
              const tempLink = document.createElement('a');
              tempLink.style.display = 'none';
              tempLink.href = blobURL;
              tempLink.setAttribute('download', filename);
              // Safari thinks _blank anchor are pop ups. We only want to set _blank
              // target if the browser does not support the HTML5 download attribute.
              // This allows you to download files in desktop safari if pop up blocking
              // is enabled.
              if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
              }
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(blobURL);
              }, 100);
            }
          })
    },
    async downloadInvoice() {
      let billIds = [];
      this.exportData.forEach(data => {
        if (data.bill) {
          billIds.push(data.bill.id);
        }
      });
      await sessionHandler();
      await this.$http
          .get(
              getFilesOfBillsURL()
              , {
                mode: requestMode(),
                params: {"billIds": billIds.join(',')},
                headers: {
                  'Accept': 'application/octet-stream',
                  'Content-Type': 'application/octet-stream',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
                responseType: 'arraybuffer'
              }
          )
          .then((d) => {
            if (d.status === 200) {
              let filename = "Bill.zip";
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              const blob = new Blob([d.data], {type: 'application/octet-stream'});
              if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE doesn't allow using a blob object directly as link href.
                // Workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
                return;
              }
              // Other browsers
              // Create a link pointing to the ObjectURL containing the blob
              const blobURL = window.URL.createObjectURL(blob);
              const tempLink = document.createElement('a');
              tempLink.style.display = 'none';
              tempLink.href = blobURL;
              tempLink.setAttribute('download', filename);
              // Safari thinks _blank anchor are pop ups. We only want to set _blank
              // target if the browser does not support the HTML5 download attribute.
              // This allows you to download files in desktop safari if pop up blocking
              // is enabled.
              if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
              }
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              setTimeout(() => {
                // For Firefox, it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(blobURL);
              }, 100);
            }
          })
    },
    async searchForSurname() {
      await this.getData(true)
    },
    async reassignItem(item) {

      //  if Session isValid
      await sessionHandler();
      this.isLoadingTable = true
      this.$http
          .patch(
              this.url + "/" + item.id,
              {"createdBy": this.sortedCreator.username}
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then(() => {
            this.reassignDialog = false;
            this.getData(false)
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAB2", "error");
            }
          })
          .finally(() => {
            this.isLoadingTable = false;
          })
    },
    // search to table view Request
    async sendRequest(URL) {
      // set Loading true
      this.isLoadingTable = true;

      // init Request Data
      let parameter = {
        'createdAtFrom': this.createDate[0],
        'createdAtTo': this.createDate[1],
      }
      if (typeof this.bill !== "undefined") {
        let bill = {billId: this.bill.id};
        parameter = {...parameter, ...bill};

      }
      if (typeof this.creator !== "undefined" && this.creator) {
        let username = {createdBy: this.creator.username};
        parameter = {...parameter, ...username};
      }

      //  if Session isValid
      await sessionHandler();
      // table data request
      await this.$http
          .get(
              URL
              , {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              response.data.forEach(item => {
                item.closed = item.closed ? "Ja" : "Nein";
                if(item.lawyerCouldNotHelpCustomer) {
                  item.aragDataType = "Servicepauschale"
                }
              })
              this.exportData = response.data
              this.unfilteredData = response.data
              showAlert(i18n.t('success.loaded'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-sR1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-sR2", "error");
            }
          })
          .finally(() => {
            this.isLoadingTable = false;
          })
    },
    formatNumber(number) {
        let formattedNumber;

        if (this.isAlreadyFormatted(number)) {
          return number;
        }
        if (number.length === 8) {
          // policyNumber format: 1.123.123.1
          formattedNumber = number.slice(0, 1) + '.' +
              number.slice(1, 4) + '.' +
              number.slice(4, 7) + '.' +
              number.slice(7, 8);
        } else if (number.length === 9) {
          // serviceNumberError format: 12-123456-1
          formattedNumber = number.slice(0, 2) + '-' +
              number.slice(2, 8) + '-' +
              number.slice(8, 9);
        } else {
          return number;
        }
        this.searchTable = formattedNumber;
        return formattedNumber;
    },

    async sendSearchRequest(URL, formatRequired) {
      // set Loading true
      this.isLoadingTable = true;

      // init Request Data
      let parameter = {
        'fullNameContains': this.searchTable
      }
      if (this.isValid(this.searchTable)) {

        if (this.contractClaimSwitch) {
          parameter = {"claimNumberContains": formatRequired ? this.formatNumber(this.searchTable) : this.searchTable}
        } else {
          parameter = {"contractNumberContains": formatRequired ? this.formatNumber(this.searchTable) : this.searchTable}
        }
      }
      if (typeof this.bill !== "undefined") {
        let bill = {billId: this.bill.id};
        parameter = {...parameter, ...bill};

      }
      if (typeof this.creator !== "undefined" && this.creator) {
        let username = {createdBy: this.creator.username};
        parameter = {...parameter, ...username};
      }
      //  if Session isValid
      await sessionHandler();
      // table data request
      await this.$http
          .get(
              URL
              , {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              //TODO more notAssignedWarning & notClosedWarning
              response.data.forEach(item => {
                item.closed = item.closed ? "Ja" : "Nein";
                if(item.lawyerCouldNotHelpCustomer) {
                  item.aragDataType = "Servicepauschale"
                }
              })
              this.exportData = [...response.data];
              this.exportData = this.exportData.reverse();

              this.unfilteredData = [...response.data];

              showAlert(i18n.t('success.loaded'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-sR1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              console.log("Test", error)
              showAlert(i18n.t('error.api.undefined') + "Export-sR2", "error");
            }
          })
          .finally(() => {
            this.isLoadingTable = false;
          })
    },

    // Preview insurance selector
    async getData(backendSearch) {
      switch (this.insurance.path) {
        case "arag":
          // eslint-disable-next-line no-case-declarations
          let aragHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["closed"], value: 'closed', width: '130px'},
            {text: this.tableHeaders["customerName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["contractNumber"], value: 'contractNumber', width: '180px'},
            {text: this.tableHeaders["customerPhoneNumber"], value: 'customerPhoneNumber', width: '180px'},
            {text: this.tableHeaders["customerMailAddress"], value: 'customerMailAddress', width: '180px'},
            {text: this.tableHeaders["claimNumber"], value: 'claimNumber', width: '150px'},
            {text: this.tableHeaders["serviceType"], value: 'serviceType', width: '120px'},
            {text: this.tableHeaders["serviceTypeText"], value: 'serviceTypeText', width: '120px'},
            {text: this.tableHeaders["claimDate"], value: 'claimDate', width: '140px'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {
              text: this.tableHeaders["closingCustomerMessage"],
              value: 'closingCustomerMessage',
              width: '400px',
              sortable: false
            },
            {
              text: this.tableHeaders["closingCustomerMessageEditedAt"],
              value: 'closingCustomerMessageEditedAt',
              width: '140px'
            },
            {text: this.tableHeaders["comment"], value: 'comment', sortable: false, width: '400px'},
            {text: this.tableHeaders["type"], value: 'aragDataType', width: '140px'},
          ]
          if (this.admin) {
            aragHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '60px'})
            aragHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px', sortable: false})
          }
          this.hideBill(aragHeader)

          this.url = getAragURL(!this.admin)
          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getAragURL(!this.admin));
            }
          } else {
            await this.sendRequest(getAragURL(!this.admin));
          }

          this.exportData.forEach(item => {
            let serviceType = this.la.find(l => l.id === item.serviceType)
            if (serviceType) {
              item.serviceTypeText = serviceType.name
            }
          })
          // this.unfilteredData = [...response.data];





          this.updateCounter();
          break;
        case "advocardBusiness":
          // eslint-disable-next-line no-case-declarations
          let advocardBusinessHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["customerName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["contractNumber"], value: 'contractNumber', width: '180px'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["consultationDate"], value: 'consultationDate', width: '130px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {text: this.tableHeaders["comment"], value: 'comment', sortable: false, width: '400px'},
            {
              text: this.tableHeaders["closingCustomerMessage"],
              value: 'closingCustomerMessage',
              width: '400px',
              sortable: false
            },
            {
              text: this.tableHeaders["closingCustomerMessageEditedAt"],
              value: 'closingCustomerMessageEditedAt',
              width: '140px'
            },
          ]
          if (this.admin) {
            advocardBusinessHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '100px'})
            advocardBusinessHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px'})
          }
          this.hideBill(advocardBusinessHeader)
          this.url = getAdvocardBusinessURL(!this.admin)
          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getAdvocardBusinessURL(!this.admin), true);
            }
          } else {
            await this.sendRequest(getAdvocardBusinessURL(!this.admin));
          }
          this.updateCounter();
          break;
        case "advocardPrivate":
          // eslint-disable-next-line no-case-declarations
          let advocardPrivateHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["customerName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["customerLastName"], value: 'customerLastname', width: '110px'},
            {text: this.tableHeaders["contractNumber"], value: 'contractNumber', width: '180px'},
            {text: this.tableHeaders["customerMailAddress"], value: 'customerMailAddress', width: '180px'},
            {text: this.tableHeaders["serviceNumber"], value: 'serviceNumber', width: '180px'},
            {text: this.tableHeaders["serviceType"], value: 'serviceType', width: '120px'},
            {text: this.tableHeaders["serviceTypeName"], value: 'serviceTypeName', width: '200px'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["consultationDate"], value: 'consultationDate', width: '130px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {text: this.tableHeaders["record"], value: 'record', width: '400px'},
            {text: this.tableHeaders["closed"], value: 'closed', width: '130px'},
            {text: this.tableHeaders["closingReport"], value: 'closingReport', width: '400px'},
            {text: this.tableHeaders["type"], value: 'advocardPrivateDataType', width: '400px'},
            {
              text: this.tableHeaders["closingCustomerMessage"],
              value: 'closingCustomerMessage',
              width: '400px',
              sortable: false
            },
            {
              text: this.tableHeaders["closingCustomerMessageEditedAt"],
              value: 'closingCustomerMessageEditedAt',
              width: '140px'
            },
          ]
          if (this.admin) {
            advocardPrivateHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '100px'})
            advocardPrivateHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px'})
          }
          this.hideBill(advocardPrivateHeader)
          this.url = getAdvocardPrivateURL(!this.admin)

          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getAdvocardPrivateURL(!this.admin), true);
            }
          } else {
            await this.sendRequest(getAdvocardPrivateURL(!this.admin));
          }
          this.updateCounter();
          break;
        case "advocardInternational":
          // eslint-disable-next-line no-case-declarations
          let advocardInternationalHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["customerName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["customerLastName"], value: 'customerLastname', width: '110px'},
            {text: this.tableHeaders["contractNumber"], value: 'contractNumber', width: '180px'},
            {text: this.tableHeaders["customerMailAddress"], value: 'customerMailAddress', width: '180px'},
            {text: this.tableHeaders["serviceNumber"], value: 'serviceNumber', width: '180px'},
            {text: this.tableHeaders["serviceType"], value: 'serviceType', width: '120px'},
            {text: this.tableHeaders["serviceTypeName"], value: 'serviceTypeName', width: '200px'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["consultationDate"], value: 'consultationDate', width: '130px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {text: this.tableHeaders["record"], value: 'record', width: '400px'},
            {text: this.tableHeaders["closed"], value: 'closed', width: '130px'},
            {text: this.tableHeaders["closingReport"], value: 'closingReport', width: '400px'},
            {text: this.tableHeaders["type"], value: 'advocardInternationalDataType', width: '400px'},
            {
              text: this.tableHeaders["closingCustomerMessage"],
              value: 'closingCustomerMessage',
              width: '400px',
              sortable: false
            },
            {
              text: this.tableHeaders["closingCustomerMessageEditedAt"],
              value: 'closingCustomerMessageEditedAt',
              width: '140px'
            },
          ]
          if (this.admin) {
            advocardInternationalHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '100px'})
            advocardInternationalHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px'})
          }
          this.hideBill(advocardInternationalHeader)
          this.url = getAdvocardInternationalURL(!this.admin)

          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getAdvocardInternationalURL(!this.admin), true);
            }
          } else {
            await this.sendRequest(getAdvocardInternationalURL(!this.admin));
          }
          this.updateCounter();
          break;
        case "roland":
          // eslint-disable-next-line no-case-declarations
          let rolandHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["customerFirstName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["customerLastName"], value: 'customerLastname', width: '115px'},
            {text: this.tableHeaders["contractNumber"], value: 'contractNumber', width: '180px'},
            {text: this.tableHeaders["company"], value: 'company', width: '100px'},
            {text: this.tableHeaders["callbackDate"], value: 'callbackDate', width: '130px'},
            {text: this.tableHeaders["fieldOfLaw"], value: 'fieldOfLaw', width: '150px'},
            {text: this.tableHeaders["subInsurance"], value: 'subInsurance', width: '150px'},
            {text: this.tableHeaders["customerMailAddress"], value: 'customerMailAddress'},
            {text: this.tableHeaders["customerMobileNumber"], value: 'customerMobileNumber'},
            {text: this.tableHeaders["customerPhoneNumber"], value: 'customerPhoneNumber'},
            {text: this.tableHeaders["customerZipCode"], value: 'customerZipCode', width: '90px'},
            {text: this.tableHeaders["customerCity"], value: 'customerCity'},
            {text: this.tableHeaders["customerStreet"], value: 'customerStreet'},
            {text: this.tableHeaders["customerStreetnumber"], value: 'customerStreetNumber'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["consultationDate"], value: 'consultationDate', width: '130px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {text: this.tableHeaders["comment"], value: 'comment', width: '400px'},
          ]
          if (this.admin) {
            rolandHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '50px'})
            rolandHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px'})
          }
          this.hideBill(rolandHeader)
          this.url = getRolandURL(!this.admin)
          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getRolandURL(!this.admin));
            }
          } else {
            await this.sendRequest(getRolandURL(!this.admin));
          }
          this.updateCounter();
          break;
        case "MPU":
          // eslint-disable-next-line no-case-declarations
          let mpuHeader = [{
            text: this.tableHeaders["billDescription"],
            align: 'start',
            width: '150px',
            sortable: false,
            value: 'bill.description',
          },
            {text: this.tableHeaders["closed"], value: 'closed', width: '130px'},
            {text: this.tableHeaders["customerFirstName"], value: 'customerName', width: '100px'},
            {text: this.tableHeaders["customerLastName"], value: 'customerLastname', width: '115px'},
            {text: this.tableHeaders["customerMailAddress"], value: 'customerMailAddress'},
            {text: this.tableHeaders["customerMobileNumber"], value: 'customerMobileNumber'},
            {text: this.tableHeaders["customerZipCode"], value: 'customerZipCode', width: '90px'},
            {text: this.tableHeaders["customerCity"], value: 'customerCity'},
            {text: this.tableHeaders["customerStreet"], value: 'customerStreet'},
            {text: this.tableHeaders["customerStreetnumber"], value: 'customerStreetNumber'},
            {text: this.tableHeaders["createdBy"], value: 'createdBy', width: '150px'},
            {text: this.tableHeaders["createdAt"], value: 'createdAt', width: '140px'},
            {text: this.tableHeaders["reason"], value: 'reason', width: '400px'},
          ]
          if (this.admin) {
            mpuHeader.push({text: this.tableHeaders["delete"], value: 'actions', width: '50px'})
            mpuHeader.push({text: this.tableHeaders["reassign"], value: 'reassign', width: '50px'})
            mpuHeader.push({text: this.tableHeaders["generateBill"], value: 'id', width: '50px'})
          }
          this.url = getMpuURL()

          this.hideBill(mpuHeader)

          if (backendSearch) {
            if (this.searchTable.length > 0) {
              await this.sendSearchRequest(getMpuURL());
            }
          } else {
            await this.sendRequest(getMpuURL());
          }
          this.updateCounter();
          break;
      }
    },

    // Only allow dates from - to + 90
    allowedDays(val) {
      //IF ADMIN
      if (this.admin) {
        return true
      }
      let firstDateMinus = new Date(this.createDate[0] + 'T10:00:00Z');
      let firstDatePlus = new Date(this.createDate[0] + 'T10:00:00Z');
      if (this.createDate[0] && !this.createDate[1]) {
        firstDateMinus.setDate(firstDateMinus.getDate());
        firstDatePlus.setDate(firstDatePlus.getDate() + 90);
        if (firstDateMinus.toISOString().substr(0, 10) > val) {
          return false
        }
        if (firstDatePlus.toISOString().substr(0, 10) < val) {
          return false
        }
      }
      return true
    },

    // Bills Autocomplete Get/Update List
    async getAllBills() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getBillURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.bills = response.data
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAB1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAB2", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },
    async getAllCreators() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.creators = response.data
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },
    async getAllCreatorsWithFilter(datatype, insurance, type, item) {
      this.selectedItem = item;
      this.sortedCreator = item.createdBy
      this.reassignDialog = true;

      //  if Session isValid
      await sessionHandler();
      // console.log(datatype)
      await this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.sortedCreators = response.data.filter(creator => {
                return creator[insurance + "Permissions"][type]
              })
              // console.log(this.sortedCreators, datatype, insurance, type)
              if (this.sortedCreators.length === 0) {
                showAlert("Keine Anwälte haben diese Berechtigung", "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Export-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },

    // User hide Bill Description
    hideBill(header) {
      if (!this.admin) {
        this.headers = header.filter(item => {
          return item.text !== this.tableHeaders["billDescription"];
        })
      } else {
        this.headers = header;
      }
    },

    //export to csv
    exportCSV() {
      let exportCSVData = this.exportData
      if (!this.exportAssigned) {
        exportCSVData = exportCSVData.filter(data => {
          return data.advocardPrivateDataType !== "VermittlungMediation" && data.advocardPrivateDataType !== "VermittlungCoaching"
        })
        exportCSVData = exportCSVData.filter(data => {
          return data.advocardInternationalDataType !== "VermittlungMediation" && data.advocardInternationalDataType !== "VermittlungCoaching"
        })
      }
      exportCSVData[0].alwaysStag = "STAG"
      downloadCSV(exportCSVData, this.insurance.fileName, this.createDate, null, this.aragOnlyDocCheck);
    },
    resetFilter() {
      // reset filter
      this.filters = [];
      this.filter = [];
      // add all filters based on permission
      if (this.insurance.path !== "MPU") {
        Object.entries(reactiveStorage.user.permissions.insurance[this.insurance.path + "DataType"]).forEach(([key, value]) => {
          if (value) {
            // nerv nicht
          }
            this.filters.push({type: key, counter: 0, counter2: 0})
            this.filter.push({type: key, counter: 0, counter2: 0})
        })
      }
    },
    updateCounter() {
      this.resetFilter();
      this.filters.forEach((item) => {
        // Schriftlich und Mündlich für OnlineBeratung
        const currentDataType = this.exportData.filter((data) => data[this.insurance.path + "DataType"] === item.type)


        if (item.type === "OnlineBeratung") {
          item.counter = currentDataType.filter((data) => data.closingCustomerMessage).length
          item.counter2 = currentDataType.filter((data) => !data.closingCustomerMessage).length
        } else {
          item.counter = currentDataType.length
        }
        item.unassigned = currentDataType.filter(data => {
          return data.createdBy === null
        }).length
        item.open = currentDataType.filter(data => {
          return data.closed === false
        }).length
      })
      // remove all empty filters
      this.filter = this.filters.filter((item) => item.counter !== 0 || item.counter2 !== 0)
      this.filters = this.filters.filter((item) => item.counter !== 0 || item.counter2 !== 0)
    }
  },
  watch: {
    searchBill() {
      // Items have already been loaded
      if (this.bills.length > 0) return

      // Items have already been requested
      if (this.isLoadingBill) return

      this.isLoadingBill = true

      // Lazily load input items
      this.getAllBills();
    },
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    },
    sortedSearchCreator() {
      // Items have already been loaded
      if (this.sortedCreators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreatorsWithFilter();

    },
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    inputLables() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.inputField')));
    },
    tableHeaders() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.tableHeader')));
    },
    insuranceRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
    dateRangeText: {
      get() {
        let formattedDates = [];
        this.createDate.forEach(date => {
          formattedDates.push(dateFormatter(date))
        })
        return formattedDates.length > 0 ? formattedDates.join(' bis ') : ''
      },
      set() {
        this.createDate = []
        let formattedDates = [];
        this.createDate.forEach(date => {
          formattedDates.push(dateFormatter(date))
        })
        return formattedDates.length > 0 ? formattedDates.join(' bis ') : ''
      }
    },
    timeRules() {
      return [
        this.createDate.length === 2 || this.errorMessage["twoDatesNeeded"],
      ]
    },
  },
  mounted() {
    // init Filter values based on permissions
    this.exportAssigned = !this.admin
    this.resetFilter();
  }
}
</script>

<style scoped>

</style>
